import { lazy } from "react";

const AppRoutes = [
  {
    path: "/dashboard",
    component: lazy(() => import("../../views/dashboard")),
    meta: { action: "read", resource: "USER", navLink: "/dashboard" },
  },
  {
    path: "/user/re-active",
    component: lazy(() => import("../../views/apps/user/list")),
    meta: { action: "manage", resource: "all", navLink: "/user" },
  },
  {
    path: "/user/:id",
    component: lazy(() => import("../../views/apps/user/view")),
    meta: {
      navLink: "/user",
      action: "manage",
      resource: "all",
    },
  },
  {
    path: "/user",
    component: lazy(() => import("../../views/apps/user/list")),
    meta: { action: "manage", resource: "all", navLink: "/user" },
  },
  {
    path: "/facility/re-active",
    component: lazy(() => import("../../views/apps/facility/list")),
    meta: { action: "manage", resource: "all", navLink: "/facility" },
  },
  {
    path: "/facility/:id",
    component: lazy(() => import("../../views/apps/facility/view")),
    meta: {
      navLink: "/facility",
      action: "manage",
      resource: "all",
    },
  },
  {
    path: "/facility",
    component: lazy(() => import("../../views/apps/facility/list")),
    meta: { action: "manage", resource: "all", navLink: "/facility" },
  },
  {
    path: "/calendar",
    component: lazy(() => import("../../views/apps/calendar/view")),
    meta: { action: "manage", resource: "all", navLink: "/calendar" },
  },
];

export default AppRoutes;
