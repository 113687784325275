// ** React Imports
import { Fragment } from "react";

// ** Custom Components
import NavbarBookmarks from "./NavbarBookmarks";

const ThemeNavbar = (props) => {
  // ** Props
  const { setMenuVisibility } = props;

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
    </Fragment>
  );
};

export default ThemeNavbar;
