import axios from "axios";
import { apiUrl } from "../../../configs/enviroments";
import jwtDefaultConfig from "./jwtDefaultConfig";
require("dotenv").config();

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        config.baseURL = apiUrl;
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;

        // ** if (status === 401) {
        if (response && response.status === 401) {
          delete axios.defaults.headers.common["Authorization"];
          localStorage.clear();
          window.location.href = "/";
        }
        return Promise.reject(error);
      }
    );
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  socialLogin(...args) {
    return axios.post(this.jwtConfig.socialLogin, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  forgotPassword(...args) {
    return axios.post(this.jwtConfig.forgotPasswordEndpoint, ...args);
  }

  resetPassword(...args) {
    return axios.post(this.jwtConfig.resetPasswordEndpoint, ...args);
  }

  // ** Request for User

  getAllUser(...args) {
    return axios.get(this.jwtConfig.getAllUser, ...args);
  }

  createUser(...args) {
    return axios.post(this.jwtConfig.createUser, ...args);
  }

  getInfoUser(userId, ...args) {
    return axios.get(`${this.jwtConfig.getInfoUser}/${userId}`, ...args);
  }
  getInfoSelf(...args) {
    return axios.get(`${this.jwtConfig.getInfoUser}/me`, ...args);
  }

  deleteUser(...args) {
    return axios.delete(`${this.jwtConfig.getInfoUser}/${args}`);
  }

  updateUser(...args) {
    return axios.put(this.jwtConfig.updateUser, ...args);
  }
  reActiveUser(...args) {
    return axios.put(`${this.jwtConfig.updateUser}re-active`, ...args);
  }

  updateUserByAdmin(id, ...args) {
    return axios.put(`${this.jwtConfig.updateUser}${id}`, ...args);
  }

  resendWelcome(id, ...args) {
    return axios.get(`${this.jwtConfig.resendWelcome}/${id}`, ...args);
  }

  // ** Request for Facilities

  getAllFacilities(...args) {
    return axios.get(this.jwtConfig.facilities, ...args);
  }

  createFacilities(...args) {
    return axios.post(this.jwtConfig.facilities, ...args);
  }

  getFacility(id, ...args) {
    return axios.get(`${this.jwtConfig.facilities}/${id}`, ...args);
  }

  reActiveFacilities(...args) {
    return axios.put(`${this.jwtConfig.facilities}/re-active`, ...args);
  }
  updateFacilities(id, ...args) {
    return axios.put(`${this.jwtConfig.facilities}/${id}`, ...args);
  }

  deleteFacilities(id, ...args) {
    return axios.delete(`${this.jwtConfig.facilities}/${id}`, ...args);
  }

  //humanity
  getLocations(...args) {
    return axios.get(`${this.jwtConfig.humanity}/locations`, ...args);
  }
}
