// ** React Imports
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

// ** Config
import themeConfig from "@configs/themeConfig";

import UserDropdown from "./UserDropdown";

const VerticalMenuHeader = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props;

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  return (
    <div className="navbar-header">
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item mr-auto">
          <NavLink to="/" className="navbar-brand">
            <span className="brand-logo">
              <img src={themeConfig.app.appLogoImage} alt="logo" />
            </span>
          </NavLink>
          {/* <div className="menu-user">
            <UserDropdown />
          </div> */}
        </li>
      </ul>
    </div>
  );
};

export default VerticalMenuHeader;
