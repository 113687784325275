// ** Auth Endpoints
export default {
  // auth
  loginEndpoint: "/api/v1/sign-in",
  forgotPasswordEndpoint: "/api/v1/forgot-password",
  resetPasswordEndpoint: "/api/v1/set-password",
  registerEndpoint: "/api/v1/sign-up",
  inviteUser: "api/v1/auth/invite-user",
  socialLogin: "api/v1/auth/sign-in-social",
  resendWelcome: "/api/v1/auth/resend-email",

  // manage user
  getAllUser: "/api/v1/users",
  getInfoUser: "/api/v1/users",
  createUser: "/api/v1/users",
  deleteUser: "/api/v1/users/",
  updateUser: "/api/v1/users/",

  //facilities
  facilities: "/api/v1/facilities",

  //humanity
  humanity: "/api/v1/humanity",

  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
};
