// ** React Imports
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { ChevronRight } from "react-feather";
import Avatar from "@components/avatar";
import defaultAvatar from "@src/assets/images/portrait/small/avatar-fake.png";
const UserDropdown = () => {
  const history = useHistory();
  // ** Store Vars

  // ** State
  const [userData, setUserData] = useState({});

  //** ComponentDidMount
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
  }, []);

  return (
    <div
      className="menu-user"
      onClick={(e) => {
        e.preventDefault();
        history.push("/pages/profile");
      }}
    >
      <Avatar
        img={defaultAvatar}
        imgHeight="40"
        imgWidth="40"
        status="online"
      />

      <div className="user-info">
        <span className="user-name">
          {userData?.firstName?.split(" ")[0]}{" "}
          {userData?.lastName?.split(" ")[0]}
        </span>
        <span className="user-role">
          {userData?.role?.charAt(0).toUpperCase() +
            userData?.role?.slice(1).toLowerCase()}
        </span>
      </div>
      <div>
        <ChevronRight color="#00779B" size={24} />
      </div>
    </div>

    //   <UncontrolledDropdown className="menu-user-custom">
    //     <DropdownToggle
    //       href="/"
    //       onClick={(e) => e.preventDefault()}
    //       className="dropdown-toggle-custom"
    //     >
    //       <div className="dropdown-title-custom">
    //         <User size={14} className="mr-75" />
    //         <span>Hi, {userData?.first_name?.split(" ")[0]}</span>
    //         <ChevronDown />
    //       </div>
    //     </DropdownToggle>
    //     <DropdownMenu down="true" className="dropdown-menu-custom">
    //       <DropdownItem tag={Link} to="/pages/profile">
    //         <User size={14} className="mr-75" />
    //         <span className="align-middle">Profile</span>
    //       </DropdownItem>
    //       <DropdownItem
    //         tag={Link}
    //         to="/login"
    //         onClick={() => dispatch(handleLogout())}
    //       >
    //         <Power size={14} className="mr-75" />
    //         <span className="align-middle">Logout</span>
    //       </DropdownItem>
    //     </DropdownMenu>
    //   </UncontrolledDropdown>
  );
};

export default UserDropdown;
