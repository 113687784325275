import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faBroadcastTower,
  faUsers,
  faCogs,
  faChartBar,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  Layers,
  MessageSquare,
  TrendingUp,
  User,
  Home,
  Columns,
  Calendar,
} from "react-feather";

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    // icon: <FontAwesomeIcon icon={faUsers} size="2x" />,
    icon: <Columns />,
    navLink: "/dashboard",
    action: "read",
    resource: "USER",
  },
  {
    id: "users",
    title: "User",
    icon: <User />,
    navLink: "/user",
    action: "manage",
    resource: "all",
  },
  {
    id: "facilities",
    title: "Facilities",
    icon: <Home />,
    navLink: "/facility",
    action: "manage",
    resource: "all",
  },
  {
    id: "calendar",
    title: "Calendar",
    icon: <Calendar />,
    navLink: "/calendar",
    action: "manage",
    resource: "all",
  },
];
