// ** Routes Imports
import AppRoutes from "./Apps";
import PagesRoutes from "./Pages";

// ** Document title
const TemplateTitle = "%s";

// ** Default Route
const DefaultRoute = "/dashboard";

// ** Merge Routes
const Routes = [...AppRoutes, ...PagesRoutes];

export { DefaultRoute, TemplateTitle, Routes };
