// ** React Imports
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

// ** Config
import themeConfig from "@configs/themeConfig";

import UserDropdown from "./UserDropdown";

const VerticalMenuFooter = (props) => {
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover,
  } = props;

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([]);
  }, [menuHover, menuCollapsed]);

  return (
    <div className="navbar-footer">
      <UserDropdown />
    </div>
  );
};

export default VerticalMenuFooter;
